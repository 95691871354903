<template>
  <div>
    <menu-desktop v-if="!isMobile" :pages="pages"></menu-desktop>
    <menu-mobile v-if="isMobile" :pages="pages"></menu-mobile>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    MenuDesktop: () => import("@/components/MenuDesktop.vue"),
    MenuMobile: () => import("@/components/MenuMobile.vue")
  },
  props: ["pages"],
  data: () => ({
    isMobile: false
  }),
  methods: {
    pageClicked(page) {
      this.$router.push({ name: page.id });
    },
    setWidth() {
      const NARROW = window.innerWidth < 768;
      const SHORT = window.innerHeight < 500;
      this.isMobile = NARROW || SHORT;
      this.setVh();
    },
    setVh() {
      // fixes a mobile browser bug by manually setting the vh val on resize
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  created() {
    window.addEventListener("resize", this.setWidth);
  },
  mounted() {
    this.setWidth();
    console.log("mount menu");
    this.$hub.$emit("startScreenSaverCheck");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  }
};
</script>

<style lang="scss">
.retina-menu {
  // first, a fallback for browsers that don't support dark magic
  height: 100vh;
  // now let's calculate our menu height
  // the --vh custom property is set in the setVh() method above
  // it helps with some mobile browsers that don't support 100vh correctly
  // 125px logo + 40px footer = 205px not in menu
  // so the menu is everything else
  max-height: calc((var(--vh, 1vh) * 100) - 205px);
}
#retina-footer {
  margin-left: calc((100vw - 1800px) / 2);
  width: calc(100vw - 20px);
  max-width: 1800px;
  min-height: 90px;
  line-height: 40px;
  display: flex;
  padding: 12px;
  position: absolute;
  bottom: 0;
  background-color: #eb1600;
  z-index: 5;
  a {
    opacity: 0.66;
    color: white;
  }
}
@media (max-width: 1800px) {
  #retina-footer {
    margin-left: 10px;
  }
}
@media (max-width: 850px) {
  #retina-footer {
    margin-left: 0;
    width: 100vw;
  }
}
</style>
